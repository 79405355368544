import React, { useState } from "react";
import { List } from "@refinedev/mui";
import {
    IResourceComponentsProps,
} from "@refinedev/core";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import { CreateButton } from "components/buttons/create";
import { BillsListContainer } from "components/BillsListContainer";

export const BillsList: React.FC<IResourceComponentsProps> = () => {
    const [searchText, setSearchText] = useState("");

    const handleSearch = (event: {
        target: { value: React.SetStateAction<string> };
    }) => {
        setSearchText(event.target.value);
    };

    return (
        <Box>
            <List
                canCreate={true}
                headerButtons={[
                    <CreateButton
                        key={"createButton"}
                        sx={{ width: "10rem", textTransform: "capitalize" }}
                    >
                        Upload A Bill
                    </CreateButton>,
                ]}
                wrapperProps={{
                    className: "bills",
                }}
            >
                <TextField
                    label="Search"
                    variant="outlined"
                    size="small"
                    value={searchText}
                    onChange={handleSearch}
                    style={{ margin: "-20px 10px 10px 0", width: "16rem" }}
                />
                <BillsListContainer searchText={searchText} />
            </List>
        </Box>
    );
};
