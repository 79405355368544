import React, { useContext, useEffect, useState } from "react";
import DarkModeOutlined from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlined from "@mui/icons-material/LightModeOutlined";

import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
    BaseRecord,
    useGetIdentity,
    useOne,
} from "@refinedev/core";
import { RefineThemedLayoutV2HeaderProps } from "@refinedev/mui";
import { HamburgerMenu } from "components/themedLayout/hamburgerMenu";
import { ColorModeContext } from "../../contexts/ColorModeContext";
import { useSharedState } from "contexts/SharedStateContext";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Tooltip } from "@mui/material";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import UserProfile from "components/UserProfile";

// Types
import IUser from "types/IUser";
import { NotificationsHeaderIcon } from "./NotificationsHeaderIcon";

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({
    sticky = true,
}) => {
    const { mode, setMode } = useContext(ColorModeContext);
    const { sharedState, setSharedState } = useSharedState();

    const { data: user } = useGetIdentity<IUser>();

    const [avatarUrl, setAvatarUrl] = useState<string | undefined>();

    const identity = JSON.parse(localStorage.getItem("profile") as string);

    const {
        data: tenant,
        isLoading,
    }: { data?: BaseRecord; isLoading: boolean } = useOne({
        resource: "tenants",
        id: identity?.tenant_id,
    });

    useEffect(() => {
        if (sharedState.updatedAvatarUrl) {
            setAvatarUrl(sharedState.updatedAvatarUrl);
        } else {
            if (user?.avatar_url) setAvatarUrl(user?.avatar_url);
        }
    }, [sharedState.updatedAvatarUrl, user?.avatar_url]);

    const handleOpenUserProfileDrawer = (
        event: React.MouseEvent<HTMLElement>
    ) => {
        setSharedState((prevState) => ({
            ...prevState,
            userProfileDrawerOpen: !prevState.userProfileDrawerOpen,
        }));
    };

    return (
        <AppBar
            position={sticky ? "sticky" : "relative"}
            sx={{
                borderRadius: "0 0 10px 10px",
                backgroundImage: "none",
                boxShadow: "none",
                backgroundColor: mode === "dark" ? "#212133" : "#fff",
                width: "auto",
            }}
        >
            <Toolbar>
                <Stack direction="row" width="100%" alignItems="center">
                    <HamburgerMenu />
                    <>
                        <HomeWorkIcon
                            sx={{
                                color: mode === "dark" ? "#fff" : "#000",
                                opacity: 0.35,
                                mr: ".75rem",
                            }}
                        />
                        {isLoading ? (
                            <CircularProgress size={20} />
                        ) : (
                            <Typography
                                sx={{
                                    display: {
                                        xs: "none",
                                        sm: "inline-block",
                                        minWidth: "fit-content",
                                        color:
                                            mode === "dark"
                                                ? "#fff"
                                                : "#212121",
                                    },
                                }}
                            >
                                {tenant?.data?.Name}
                            </Typography>
                        )}
                    </>
                    <Stack
                        direction="row"
                        width="100%"
                        justifyContent="flex-end"
                        alignItems="center"
                        gap=".125rem"
                    >

                        <IconButton
                            color="inherit"
                            onClick={() => {
                                setMode();
                            }}
                        >
                            {mode === "dark" ? (
                                <LightModeOutlined
                                    sx={{
                                        color:
                                            mode === "dark"
                                                ? "#fff"
                                                : "#212121",
                                    }}
                                />
                            ) : (
                                <DarkModeOutlined
                                    sx={{
                                        color:
                                            mode === "dark"
                                                ? "#fff"
                                                : "#212121",
                                    }}
                                />
                            )}
                        </IconButton>

                        <NotificationsHeaderIcon />

                        {(user?.avatar_url || user?.full_name) && (
                            <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="Open settings">
                                    <IconButton
                                        onClick={handleOpenUserProfileDrawer}
                                        sx={{ p: 0 }}
                                    >
                                        <Avatar
                                            src={
                                                "https://portal.compareabill.ai/storage/v1/object/public/avatars/" +
                                                avatarUrl
                                            }
                                            alt={user?.full_name}
                                            sx={{
                                                textDecoration: "none",
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                                <UserProfile />
                            </Box>
                        )}
                    </Stack>
                </Stack>
            </Toolbar>
        </AppBar>
    );
};
