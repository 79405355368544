import React, { useContext, useEffect, useState } from "react";
import {
    useDataGrid,
    EditButton,
    ShowButton,
    List,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
    IResourceComponentsProps,
    useMany,
    useTranslate,
} from "@refinedev/core";
import { Grid, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import { ColorModeContext } from "contexts/ColorModeContext";
import dayjs from "dayjs";
import { DeleteButton } from "components/buttons/delete";
import { TablePaginationActions } from "components/TablePaginationActions";

export const NotificationsList: React.FC<IResourceComponentsProps> = () => {
    const theme = useTheme();
    const { mode } = useContext(ColorModeContext);
    const translate = useTranslate();
    const [viewAllNotificationsMode, setViewAllNotificationsMode] = useState(false)
    const [filters, setFilters] = useState({})
    const identity = JSON.parse(localStorage.getItem("profile") as string);

    useEffect(() => {
        if (viewAllNotificationsMode) {
            setFilters({})
        } else {
            setFilters({ permanent: [{ field: "resolved", operator: "eq", value: false }] })
        }
    }, [viewAllNotificationsMode]);

    const { dataGridProps } = useDataGrid({
        filters,
        meta: {
            select: "id,title,tenant_id,created_at,details",
        },
        sorters: {
            initial: [{ field: "created_at", order: "desc" }],
        },
    });

    const { data: tenantsData } = useMany({
        resource: "tenants",
        ids: dataGridProps?.rows?.map((item: any) => item?.tenant_id) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
        meta: {
            select: "id,Name",
        },
    });

    const columnVisible = {
        id: false,
        tenant_id: identity.role === "manager" || identity.role === "admin",
    };

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "id",
                headerName: translate("Notifications.fields.Id"),
                type: "number",
                minWidth: 50,
            },
            {
                field: "title",
                flex: 1,
                headerName: translate("Notifications.fields.Title"),
                minWidth: 650,

                renderCell: function render({ value, row }) {
                    return (
                        <Grid
                            key={row?.id}
                            container
                            spacing={2}
                            direction="row"
                            style={{
                                justifyContent: "space-between",
                                alignItems: "center",
                                margin: "2.25rem 0 2.1875rem",
                                width: "100%",
                                flexWrap: "nowrap",
                            }}
                        >
                            <Grid
                                item
                                style={{ padding: "0 0 0 0", width: "80%" }}
                                container
                                spacing={1}
                            >
                                <Grid item>
                                    <Typography
                                        component="div"
                                        style={{
                                            fontSize: ".9375rem",
                                        }}
                                        color={
                                            mode === "dark"
                                                ? "rgba(255, 255, 255, 0.8)"
                                                : "#212121"
                                        }
                                    >
                                        {row?.title}
                                    </Typography>
                                </Grid>
                                <Grid item style={{ paddingTop: "0" }}>
                                    <Typography
                                        component="div"
                                        style={{
                                            color: theme.palette.text.secondary,
                                            fontSize: ".8125rem",
                                            padding: "0 0 0 0",
                                        }}
                                    >
                                        {row?.details.slice(0, 100) + "..."}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                },
            },
            {
                field: "tenant_id",
                flex: 1,
                headerName: translate("Notifications.fields.Tenant"),
                minWidth: 100,
                renderCell: function render({ value }) {
                    return (
                        tenantsData?.data.find((item: any) => item.id === value)
                            ?.Name ?? ""
                    );
                },
            },
            {
                field: "created_at",
                flex: 1,
                headerName: translate("Notifications.fields.CreatedAt"),
                minWidth: 160,
                renderCell: function render({ value, row }) {
                    if (!value) return null;

                    const date = dayjs(new Date(value).toLocaleString()).format(
                        "MMM D, YYYY"
                    );
                    const time = dayjs(new Date(value).toLocaleString()).format(
                        "h:mm A"
                    );

                    return (
                        <Grid
                            key={row?.id}
                            container
                            spacing={2}
                            direction="row"
                            style={{
                                justifyContent: "space-between",
                                alignItems: "center",
                                margin: "2.25rem 0 2.1875rem",
                                width: "100%",
                                flexWrap: "nowrap",
                            }}
                        >
                            <Grid
                                item
                                style={{ padding: "0 0 0 0", width: "80%" }}
                                container
                                spacing={1}
                                flexDirection={"column"}
                            >
                                <Grid item>
                                    <Typography
                                        component="div"
                                        style={{
                                            fontSize: ".9375rem",
                                        }}
                                        color={
                                            mode === "dark"
                                                ? "rgba(255, 255, 255, 0.8)"
                                                : "#212121"
                                        }
                                    >
                                        {date}
                                    </Typography>
                                </Grid>
                                <Grid item style={{ paddingTop: "0" }}>
                                    <Typography
                                        component="div"
                                        style={{
                                            color: theme.palette.text.secondary,
                                            fontSize: ".8125rem",
                                            padding: "0 0 0 0",
                                        }}
                                    >
                                        {time}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                },
            },
            {
                field: "actions",
                headerName: translate("table.actions"),
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <EditButton hideText recordItemId={row.id} />
                            <ShowButton hideText recordItemId={row.id} />
                            {(identity.role === "manager" ||
                                identity.role === "admin") && (
                                    <DeleteButton
                                        size="small"
                                        recordItemId={row.id}
                                        hideText
                                        confirmOkText="Yes, Delete Notification"
                                    />
                                )}
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
                disableColumnMenu: true,
            },
        ],
        [
            identity.role,
            mode,
            tenantsData?.data,
            theme.palette.text.secondary,
            translate,
        ]
    );

    const onToggleViewResolved = (
        _event: any,
        view: string
    ) => {
        if (view === 'all') {
            setViewAllNotificationsMode(true)
        } else {
            setViewAllNotificationsMode(false)
        }
    };
    return (
        <List
            headerButtons={[
                <ToggleButtonGroup
                    key={"toggleView"}
                    exclusive
                    onChange={onToggleViewResolved}
                >
                    <ToggleButton
                        selected={!viewAllNotificationsMode}
                        size="small"
                        value="unresolved"
                        aria-label="unresolved"
                        key={"unresolved"}
                    >
                        Unresolved
                    </ToggleButton>
                    <ToggleButton
                        selected={viewAllNotificationsMode}
                        size="small"
                        value="all"
                        aria-label="all"
                        key={"all"}
                    >
                        All
                    </ToggleButton>
                </ToggleButtonGroup>,
            ]}
            wrapperProps={{
                className: "notifications",
            }}
        >


            <DataGrid
                {...dataGridProps}
                columns={columns}
                columnVisibilityModel={columnVisible}
                autoHeight
                rowHeight={70}
                slotProps={{
                    pagination: {
                        ActionsComponent: TablePaginationActions
                    }
                }}
            />
        </List>
    );
};
