import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { BaseKey, useMany, useNavigation, useTranslate } from "@refinedev/core";
import { ShowButton, useDataGrid } from "@refinedev/mui";
import React, { useEffect, useState } from "react";
import { TablePaginationActions } from "components/TablePaginationActions";
import { DownloadUrlField } from "./DownloadUrlField";

interface Props {
    account_id?: BaseKey
    searchText?: string;
}

export const BillsListContainer = ({ account_id, searchText }: Props) => {
    const translate = useTranslate();
    const { show } = useNavigation();
    const [filters, setFilters] = useState({});

    useEffect(() => {
        let permanentFilters = []

        // Update filters based on search text
        if (account_id) {
            permanentFilters.push({ field: "accounts_id", operator: "eq", value: account_id })
        }

        // Update filters based on search text
        if (searchText && searchText.length > 0) {
            permanentFilters.push({ field: "Documents", operator: "contains", value: searchText })
        }

        if (permanentFilters.length > 0) {
            setFilters({
                permanent: permanentFilters
            })
        } else {
            setFilters({})
        }

    }, [account_id, searchText]);

    const { dataGridProps } = useDataGrid({
        resource: 'documents',
        filters,
        meta: {
            select: "id,Reference,accounts_id,Docdate,Documents,Metadata",
        },
        sorters: {
            initial: [{ field: "Docdate", order: "desc" }],
        },
    });

    const { data: accountsData, isLoading: accountsIsLoading } = useMany({
        resource: "accounts",
        ids: dataGridProps?.rows?.map((item: any) => item?.accounts_id) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
        meta: {
            fields: ["id", "Name"],
        },
    });

    const columnVisible = {
        id: false,
    };

    const columns = React.useMemo<GridColDef[]>((): GridColDef[] => {

        const column_config = [
            {
                field: "id",
                headerName: translate("Bills.fields.id"),
                type: "number",
                minWidth: 50,
            },
            {
                field: "Reference",
                flex: 1,
                headerName: translate("Bills.fields.Reference"),
                minWidth: 150,
            },
            {
                field: "accounts_id",
                flex: 1,
                headerName: translate("Bills.fields.Accounts"),
                minWidth: 150,
                renderCell: function render({ value }: { value: any }) {
                    if (accountsIsLoading) {
                        return "Loading...";
                    }
                    return (
                        accountsData?.data.find(
                            (item: any) => item.id === value
                        )?.Name ?? ""
                    );
                },
            },
            {
                field: "Docdate",
                flex: 1,
                headerName: translate("Bills.fields.Docdate"),
                minWidth: 100,
            },

            {
                field: "Documents",
                flex: 1,
                headerName: translate("Bills.fields.Documents"),
                minWidth: 300,
                renderCell: function render({ value }: { value: any }) {
                    return (
                        <DownloadUrlField
                            billData={value}
                            sx={{ color: "#1f7b7b", textDecoration: "none" }}
                        />
                    );
                },
                maxWidth: 600,
            },
            {
                field: "actions",
                headerName: translate("table.actions"),
                sortable: false,
                renderCell: function render({ row }: { row: any }) {
                    return (
                        <>
                            {/* <EditButton hideText recordItemId={row.id} /> */}
                            <ShowButton hideText resource="documents" recordItemId={row.id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
                disableColumnMenu: true,
            },
        ];
        return column_config.filter(
            (item: any) => {
                if (item.field !== "accounts_id") {
                    return true
                }

                return !account_id
            }
        ) as GridColDef[];
    }, [translate, accountsData, accountsIsLoading, account_id]);

    return (
        <DataGrid
            {...dataGridProps}
            columns={columns}
            columnVisibilityModel={columnVisible}
            autoHeight
            onRowClick={(params) => {
                show("documents", params.id);
            }}
            slotProps={{
                pagination: {
                    ActionsComponent: TablePaginationActions
                }
            }}
        />

    );
};
