import React from "react";
import { Edit } from "@refinedev/mui";
import {
    Box,
    TextField,
    InputAdornment,
    IconButton,
    Grid,
} from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";

import SelectField from "../../components/SelectField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AutoCompleteField from "../../components/AutoCompleteField";

import { statusOptions, refreshOptions } from "./config";
import { BackButton } from "components/buttons/back";
import { ListButton } from "components/buttons/list";

export const LoginsEdit: React.FC<IResourceComponentsProps> = () => {
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const translate = useTranslate();
    const {
        saveButtonProps,
        refineCore: { queryResult },
        register,
        control,
        formState: { errors },
    } = useForm();

    const loginsData = queryResult?.data?.data;

    const identity = JSON.parse(localStorage.getItem("profile") as string);

    return (
        <Edit
            headerButtons={[
                <ListButton
                    key={"list"}
                    sx={{
                        padding: ".25rem 1.6875rem",
                        minWidth: "7.5rem",
                        textTransform: "capitalize",
                    }}
                >
                    View All Logins
                </ListButton>,
            ]}
            saveButtonProps={saveButtonProps}
            goBack={<BackButton />}
            wrapperProps={{
                className: "logins",
            }}
        >
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <TextField
                    {...register("Name", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.Name}
                    helperText={(errors as any)?.Name?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={translate("Logins.fields.Name")}
                    name="Name"
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        {(identity.role === "manager" ||
                            identity.role === "admin") && (
                            <AutoCompleteField // Not working yet.
                                id="clients_id"
                                control={control}
                                resource="clients"
                                field="clients_id"
                                name="Name"
                                defaultValue={loginsData?.clients_id}
                                label={translate("Logins.fields.Client")}
                                error={!!(errors as any)?.Clients}
                                helperText={(errors as any)?.Clients?.message}
                            />
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={
                            identity.role === "manager" ||
                            identity.role === "admin"
                                ? 6
                                : 12
                        }
                    >
                        <AutoCompleteField // Not working yet.
                            id="downloaders_id"
                            control={control}
                            resource="downloaders"
                            field="downloaders_id"
                            name="Name"
                            defaultValue={loginsData?.downloaders_id}
                            label={translate("Logins.fields.Vendor")}
                            error={!!(errors as any)?.Vendors}
                            helperText={(errors as any)?.Vendors?.message}
                        />
                    </Grid>
                </Grid>
                <SelectField
                    id="Status"
                    name="Status"
                    label="Status"
                    margin="normal"
                    control={control}
                    defaultValue={""}
                    helperText={(errors as any)?.Status?.message}
                    error={!!(errors as any)?.Status}
                    options={statusOptions}
                />
                <TextField
                    {...register("StatusMessage")}
                    error={!!(errors as any)?.StatusMessage}
                    helperText={(errors as any)?.StatusMessage?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={translate("Logins.fields.StatusMessage")}
                    name="StatusMessage"
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <SelectField
                            id="Refresh"
                            // {...register("Refresh", {
                            //     required: "This field is required",
                            // })}
                            name="Refresh"
                            label={translate("Logins.fields.Refresh")}
                            control={control}
                            defaultValue={""}
                            margin="normal"
                            helperText={(errors as any)?.Refresh?.message}
                            error={!!(errors as any)?.Refresh}
                            options={refreshOptions}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            {...register("RefreshOffset", {
                                required: "This field is required",
                                valueAsNumber: true,
                            })}
                            error={!!(errors as any)?.RefreshOffset}
                            helperText={(errors as any)?.RefreshOffset?.message}
                            margin="normal"
                            fullWidth
                            InputProps={{ inputProps: { min: 0, max: 31 } }}
                            InputLabelProps={{ shrink: true }}
                            type="number"
                            label={translate("Logins.fields.RefreshOffset")}
                            name="RefreshOffset"
                        />
                    </Grid>
                </Grid>
                <TextField
                    id="URI"
                    defaultValue={loginsData?.URI}
                    required
                    error={!!(errors as any)?.URI}
                    helperText={(errors as any)?.URI?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    multiline
                    type={"url"}
                    label={translate("Logins.fields.URI")}
                    name="URI"
                />
                <TextField
                    {...register("Username", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.Username}
                    helperText={(errors as any)?.Username?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="email"
                    label={translate("Logins.fields.Username")}
                    name="Username"
                />
                <TextField
                    {...register("Password", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.Password}
                    helperText={(errors as any)?.Password?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? (
                                        <VisibilityOffIcon />
                                    ) : (
                                        <VisibilityIcon />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    label={translate("Logins.fields.Password")}
                    name="Password"
                />
                <TextField
                    {...register("ForwardTo")}
                    error={!!(errors as any)?.Name}
                    helperText={(errors as any)?.Name?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={translate("Logins.fields.ForwardTo")}
                    name="ForwardTo"
                />
            </Box>
        </Edit>
    );
};
