import {
    Box,
    Button,
    Divider,
    Grid,
    Popover,
    Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import LoadingIndicator from "components/LoadingIndicator";
import { useList } from "@refinedev/core";
import { useContext } from "react";
import { useSharedState } from "contexts/SharedStateContext";
import { ColorModeContext } from "contexts/ColorModeContext";

interface IUseList {
    id: number;
    Name: string;
}

export const NewNotificationsPopover = () => {
    const { mode } = useContext(ColorModeContext);
    const { sharedState, setSharedState } = useSharedState();

    const {
        data: notificationsData,
        isLoading: notificationsIsLoading,
        // isError: isErrorDataSinceDate,
    } = useList<IUseList>({
        resource: "notifications",
        filters: [
            { field: "resolved", operator: "eq", value: false }
        ],
        pagination: {
            pageSize: 10,
        },
        sorters: [{ field: "created_at", order: "desc" }],
    });

    const handleNotificationsClose = () => {
        setSharedState((prevState) => ({
            ...prevState,
            anchorElNotifications: null,
        }));
    };

    const notificationsOpen = Boolean(sharedState?.anchorElNotifications);
    const notificationsId = notificationsOpen ? "simple-popover" : undefined;

    return (
        <Popover
            id={notificationsId}
            open={notificationsOpen}
            anchorEl={sharedState?.anchorElNotifications}
            onClose={handleNotificationsClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            slotProps={{
                paper: {
                    sx: (theme) => {
                        return {
                            width: "360px",
                            backgroundColor:
                                theme.palette.mode === "light"
                                    ? "#fff"
                                    : "#313142",
                            backgroundImage: "none",
                        };
                    },
                },
            }}
        >
            <Box display="flex" flexDirection="column" maxHeight="486px">
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        p: "1rem",
                    }}
                >
                    <Box
                        sx={(theme) => ({
                            display: "flex",
                            alignItems: "center",
                            fontSize: "1rem",
                            fontFamily:
                                '"ProductSans-Bold", "Product Sans Bold", "Product Sans", sans-serif',
                            fontWeight: 700,
                            color:
                                theme.palette.mode === "light"
                                    ? "#212121"
                                    : "rgba(255, 255, 255, 0.8)",
                        })}
                    >
                        New Notifications
                    </Box>
                </Box>
                <Divider />

                {/* Scrollable Content */}
                <Box flex={1} overflow="auto">
                    <Box p="0 16px">
                        {notificationsIsLoading ? (
                            <LoadingIndicator size={50} />
                        ) : (
                            <Box display="flex">
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                    }}
                                >
                                    {notificationsData?.data?.map(
                                        (item: any) => (
                                            <Grid
                                                key={item?.id}
                                                container
                                                spacing={2}
                                                direction="row"
                                                sx={(theme) => ({
                                                    justifyContent:
                                                        "space-between",
                                                    alignItems: "center",
                                                    margin: "0",
                                                    padding:
                                                        ".875rem 0 .625rem",
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                    borderBottom:
                                                        theme.palette.mode ===
                                                            "light"
                                                            ? "1px solid #79797915"
                                                            : "1px solid #79797945",
                                                })}
                                            >
                                                <Grid
                                                    item
                                                    style={{
                                                        padding: "0 0 0 0",
                                                    }}
                                                    container
                                                    spacing={1}
                                                >
                                                    <Grid item>
                                                        <Typography
                                                            component="div"
                                                            style={{
                                                                fontSize:
                                                                    "1rem",
                                                                whiteSpace:
                                                                    "nowrap",
                                                                overflow:
                                                                    "hidden",
                                                                textOverflow:
                                                                    "ellipsis",
                                                            }}
                                                            color={
                                                                mode === "dark"
                                                                    ? "rgba(255, 255, 255, 0.8)"
                                                                    : "#212121"
                                                            }
                                                        >
                                                            {item?.title?.slice(
                                                                0,
                                                                37
                                                            ) + "..."}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        style={{
                                                            paddingTop: "0",
                                                        }}
                                                    >
                                                        <Typography
                                                            component="div"
                                                            sx={(theme) => ({
                                                                color: theme
                                                                    .palette
                                                                    .text
                                                                    .secondary,
                                                                fontSize:
                                                                    "1rem",
                                                            })}
                                                        >
                                                            {dayjs(
                                                                item?.created_at
                                                            ).format(
                                                                "MMM D, YYYY"
                                                            )}
                                                            {" | "}
                                                            {dayjs(
                                                                item?.created_at
                                                            ).format("h:mm A")}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    )}
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
                <Divider />

                {/* Footer */}
                <Box p={2}>
                    <Button
                        variant="contained"
                        sx={{
                            padding: ".25rem 1.6875rem",
                            minWidth: "7.5rem",
                            width: "100%",
                            textTransform: "capitalize",
                        }}
                        component={Link}
                        to="/Notifications"
                        onClick={handleNotificationsClose}
                    >
                        View All Notifications
                    </Button>
                </Box>
            </Box>
        </Popover>
    );
};
