import React, { useEffect, useState } from "react";
import { useDataGrid, EditButton, ShowButton, List } from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
    IResourceComponentsProps,
    useTranslate,
    useMany,
} from "@refinedev/core";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import { ChipField } from "../../components/ChipField";
import { CreateButton } from "components/buttons/create";
import { TablePaginationActions } from "components/TablePaginationActions";
import AutoCompleteField from "components/AutoCompleteField";
import { useForm } from "@refinedev/react-hook-form";
import { Button } from "@mui/material";
import useLocalStorageState from "hooks/useLocalStorageState";

const statusOptions = [
    { value: "Active", color: "#58aa02" },
    { value: "Inactive", color: "#ffab9" },
    { value: "Error", color: "#ef9a9a" },
];

const localStorageSearchTextKey = 'searchFilter-Logins-SearchText'
const localStorageClientIdKey = 'searchFilter-Logins-ClientId'

export const LoginsList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const [filters, setFilters] = useState({});
    const [searchText, setSearchText] = useLocalStorageState(localStorageSearchTextKey, "")
    const [clientId, setClientId] = useLocalStorageState(localStorageClientIdKey, undefined)

    const {
        control,
        setValue,
    } = useForm({
        warnWhenUnsavedChanges: false
    });

    useEffect(() => {
        setValue("clients_id", clientId, {
            shouldValidate: true,
            shouldDirty: true
        })
    }, [clientId, setValue]);

    useEffect(() => {
        let permanentFilters = []
        // Update filters based on search text
        if (clientId) {
            permanentFilters.push({ field: "clients_id", operator: "eq", value: clientId })
        }

        // Update filters based on search text
        if (searchText && searchText.length > 0) {
            permanentFilters.push({ field: "Name", operator: "contains", value: searchText })
        }

        if (permanentFilters.length > 0) {
            setFilters({
                permanent: permanentFilters
            })
        } else {
            setFilters({})
        }
    }, [searchText, clientId]);

    const clearFilters = () => {
        setClientId(undefined)
        setSearchText("")
    }

    const onClientsIdChange = (value: any) => {
        setClientId(value)
    }

    const { dataGridProps } = useDataGrid({
        filters: filters,
        meta: {
            select: "id,Name,Status,vendors_id,accounts(count)", //AccountsCount
        },
    });

    const { data: vendorsData, isLoading: vendorsIsLoading } = useMany({
        resource: "vendors",
        ids: dataGridProps?.rows?.map((item: any) => item?.vendors_id) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
        meta: {
            select: "id,Name",
        },
    });

    const columnVisible = {
        id: false,
    };

    const columns = React.useMemo<GridColDef[]>(() => {
        const identity = JSON.parse(localStorage.getItem("profile") as string);

        var column_config = [
            {
                field: "id",
                headerName: translate("Logins.fields.id"),
                type: "number",
                minWidth: 50,
            },

            {
                field: "Name",
                flex: 1,
                headerName: translate("Logins.fields.Name"),
                minWidth: 200,
            },
            {
                field: "vendors_id",
                flex: 1,
                headerName: translate("Logins.fields.Vendor"),
                minWidth: 150,
                renderCell: function render({ value }: { value?: any }) {
                    if (vendorsIsLoading) {
                        return "Loading...";
                    }
                    return (
                        vendorsData?.data.find((item: any) => item.id === value)
                            ?.Name ?? ""
                    );
                },
            },
            {
                field: "Status",
                flex: 1,
                headerName: translate("Logins.fields.Status"),
                minWidth: 100,
                renderCell: function render({ value }: { value?: any }) {
                    return (
                        <ChipField selected={[value]} options={statusOptions} />
                    );
                },
            },
            {
                field: "accounts",
                flex: 1,
                headerName: translate("Logins.fields.AccountsCount"),
                minWidth: 100,
                renderCell: function render({ value }: { value?: any }) {
                    return value[0].count;
                },
            },
            {
                field: "actions",
                headerName: translate("table.actions"),
                sortable: false,
                renderCell: function render({ row }: { row?: any }) {
                    return (
                        <>
                            <EditButton hideText recordItemId={row.id} />
                            <ShowButton hideText recordItemId={row.id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
                disableColumnMenu: true,
            },
        ];
        return column_config.filter(
            (item: any) =>
                item.field !== "clients_id" ||
                identity.role === "manager" ||
                identity.role === "admin"
        ) as GridColDef[];
    }, [translate, vendorsData?.data, vendorsIsLoading]);

    const handleSearch = (event: {
        target: { value: React.SetStateAction<string> };
    }) => {
        setSearchText(event.target.value);
    };

    return (
        <Box>
            <List
                headerButtons={[
                    <Button
                        key={"resetButton"}
                        sx={{ width: "10rem", textTransform: "capitalize" }}
                        onClick={clearFilters}
                    >
                        Clear Filters
                    </Button>,
                    <CreateButton
                        key={"createButton"}
                        sx={{ width: "10rem", textTransform: "capitalize" }}
                    >
                        Create New Login
                    </CreateButton>,
                ]}
                wrapperProps={{
                    className: "logins",
                }}
            >
                <TextField
                    label="Search"
                    variant="outlined"
                    size="small"
                    value={searchText}
                    onChange={handleSearch}
                    style={{ margin: "-20px 10px 10px 0", width: "16rem" }}
                />


                <AutoCompleteField
                    control={control}
                    resource="clients"
                    field="clients_id"
                    name="Name"
                    label="Client"
                    onChange={onClientsIdChange}
                />

                <DataGrid
                    {...dataGridProps}
                    columns={columns}
                    columnVisibilityModel={columnVisible}
                    autoHeight
                    slotProps={{
                        pagination: {
                            ActionsComponent: TablePaginationActions
                        }
                    }}
                />
            </List>
        </Box>
    );
};
