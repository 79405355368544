import React, { useEffect } from "react";
import { List } from "@refinedev/mui";
import {
    IResourceComponentsProps,
} from "@refinedev/core";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AutoCompleteField from "components/AutoCompleteField";
import { useForm } from "@refinedev/react-hook-form";
import { AccountsListContainer } from "components/AccountsListContainer";
import useLocalStorageState from "hooks/useLocalStorageState";
import { Button } from "@mui/material";

const localStorageSearchTextKey = 'searchFilter-Accounts-SearchText'
const localStorageLoginIdKey = 'searchFilter-Accounts-LoginId'

export const AccountsList: React.FC<IResourceComponentsProps> = () => {
    const [searchText, setSearchText] = useLocalStorageState(localStorageSearchTextKey, "")
    const [loginId, setLoginId] = useLocalStorageState(localStorageLoginIdKey, undefined)

    const {
        control,
        setValue,
    } = useForm({
        warnWhenUnsavedChanges: false
    });

    useEffect(() => {
        setValue("logins_id", loginId, {
            shouldValidate: true,
            shouldDirty: true
        })
    }, [loginId, setValue]);

    const clearFilters = () => {
        setLoginId(undefined)
        setSearchText("")
    }

    const onClientsIdChange = (value: any) => {
        setLoginId(value)
    }

    const handleSearch = (event: {
        target: { value: React.SetStateAction<string> };
    }) => {
        setSearchText(event.target.value);
    };

    return (
        <Box>
            <List
                headerButtons={[
                    <Button
                        key={"resetButton"}
                        sx={{ width: "10rem", textTransform: "capitalize" }}
                        onClick={clearFilters}
                    >
                        Clear Filters
                    </Button>,
                ]}
                wrapperProps={{
                    className: "accounts",
                }}
            >
                <TextField
                    label="Search"
                    variant="outlined"
                    size="small"
                    value={searchText}
                    onChange={handleSearch}
                    style={{ margin: "-20px 10px 10px 0", width: "16rem" }}
                />
                <AutoCompleteField
                    control={control}
                    resource="logins"
                    field="logins_id"
                    name="Name"
                    label="Login"
                    onChange={onClientsIdChange}
                />
                <AccountsListContainer login_id={loginId} searchText={searchText} />
            </List>
        </Box>
    );
};
