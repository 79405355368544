import NotificationsIcon from "@mui/icons-material/Notifications";
import { Badge, IconButton } from "@mui/material";
import { useSharedState } from "contexts/SharedStateContext";
import { NewNotificationsPopover } from "./NewNotificationsPopover";
import { useContext, useEffect, useState } from "react";
import { ColorModeContext } from "contexts/ColorModeContext";
import { supabaseClient } from "utility/supabaseClient";

export const NotificationsHeaderIcon = () => {
    const { setSharedState } = useSharedState();
    const { mode } = useContext(ColorModeContext);
    const [numUnresolved, setNumUnresolved] = useState(0);

    const handleNotificationsOpen = (event: React.MouseEvent<HTMLElement>) => {
        setSharedState((prevState) => ({
            ...prevState,
            notificationsPopoverOpen: !prevState.notificationsPopoverOpen,
            anchorElNotifications: event.currentTarget,
        }));
    };

    useEffect(() => {
        supabaseClient
            .from("notifications")
            .select("id", { count: "exact", head: true })
            .eq('resolved', false)
            .then(({ count }) => {
                setNumUnresolved(count || 0)
            })
    }, []);

    return (
        <>
            <IconButton
                aria-label="show 17 new notifications"
                color="inherit"
                style={{ marginRight: "1rem" }}
                onClick={handleNotificationsOpen}
            >
                <Badge badgeContent={numUnresolved} color="error">
                    <NotificationsIcon
                        sx={{
                            color:
                                mode === "dark"
                                    ? "#fff"
                                    : "#212121",
                        }}
                    />
                </Badge>
            </IconButton>

            <NewNotificationsPopover />
        </>
    );
}