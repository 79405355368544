import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { BaseKey, useMany, useNavigation, useTranslate } from "@refinedev/core";
import { ShowButton, useDataGrid } from "@refinedev/mui";
import React, { useEffect, useState } from "react";
import { TablePaginationActions } from "components/TablePaginationActions";
import { ChipField } from "./ChipField";

interface Props {
    login_id?: BaseKey;
    searchText?: string;
}
const statusOptions = [
    { value: "Active", color: "#58aa02" },
    { value: "Inactive", color: "#ffab9" },
    { value: "Error", color: "#ef9a9a" },
];

export const AccountsListContainer = ({ login_id, searchText }: Props) => {
    const translate = useTranslate();
    const { show } = useNavigation();
    const [filters, setFilters] = useState({});

    useEffect(() => {
        let permanentFilters = []

        // Update filters based on search text
        if (login_id) {
            permanentFilters.push({ field: "logins_id", operator: "eq", value: login_id })
        } else {
        }

        // Update filters based on search text
        if (searchText && searchText.length > 0) {
            permanentFilters.push({ field: "Name", operator: "contains", value: searchText })
        } else {
        }

        if (permanentFilters.length > 0) {
            setFilters({
                permanent: permanentFilters
            })
        } else {
            setFilters({})
        }

    }, [login_id, searchText]);

    const { dataGridProps } = useDataGrid({
        resource: 'accounts',
        filters: filters,
        meta: {
            select: "id,Name,Status,vendors_id,Last_Checked,documents(count)",
        },
        sorters: {
            initial: [{ field: "Name", order: "desc" }],
        },
    });

    const { data: vendorsData, isLoading: vendorsIsLoading } = useMany({
        resource: "vendors",
        ids: dataGridProps?.rows?.map((item: any) => item?.vendors_id) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
        meta: {
            fields: ["id", "Name"],
        },
    });

    const columnVisible = {
        id: false,
    };

    const columns = React.useMemo<GridColDef[]>((): GridColDef[] => {
        const identity = JSON.parse(localStorage.getItem("profile") as string);
        const column_config = [
            {
                field: "id",
                headerName: translate("Accounts.fields.id"),
                type: "number",
                minWidth: 50,
                show: false,
            },
            {
                field: "Name",
                flex: 1,
                headerName: translate("Accounts.fields.Name"),
                minWidth: 200,
            },
            {
                field: "Status",
                flex: 1,
                headerName: translate("Accounts.fields.Status"),
                minWidth: 100,
                renderCell: function render({ value }: { value: any }) {
                    return (
                        <ChipField selected={[value]} options={statusOptions} />
                    );
                },
            },
            {
                field: "vendors_id",
                flex: 1,
                headerName: translate("Accounts.fields.Vendor"),
                minWidth: 150,
                renderCell: function render({ value }: { value: any }) {
                    if (vendorsIsLoading) {
                        return "Loading...";
                    }
                    return (
                        vendorsData?.data.find((item: any) => item.id === value)
                            ?.Name ?? ""
                    );
                },
            },
            {
                field: "documents",
                flex: 1,
                headerName: translate("Accounts.fields.DocumentsCount"),
                minWidth: 100,
                renderCell: function render({ value }: { value?: any }) {
                    return value[0].count;
                },
            },
            {
                field: "Last_Checked",
                flex: 1,
                headerName: translate("Accounts.fields.Last_Checked"),
                minWidth: 200,
            },
            {
                field: "actions",
                headerName: translate("table.actions"),
                sortable: false,
                renderCell: function render({ row }: { row: any }) {
                    return (
                        <>
                            <ShowButton hideText resource="accounts" recordItemId={row.id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
                disableColumnMenu: true,
            },
        ];
        return column_config.filter(
            (item: any) =>
                item.field !== "clients_id" ||
                identity.role === "manager" ||
                identity.role === "admin"
        ) as GridColDef[];
    }, [translate, vendorsData?.data, vendorsIsLoading]);

    return (
        <DataGrid
            {...dataGridProps}
            columns={columns}
            columnVisibilityModel={columnVisible}
            autoHeight
            onRowClick={(params) => {
                show("accounts", params.id);
            }}
            slotProps={{
                pagination: {
                    ActionsComponent: TablePaginationActions
                }
            }}
        />
    );
};
