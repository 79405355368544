import { Create } from "@refinedev/mui";
import { Box, Grid, TextField } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";

import SelectField from "../../components/SelectField";
import { statusOptions, refreshOptions } from "./config";
import AutoCompleteField from "../../components/AutoCompleteField";
import { supabaseClient } from "utility";
import { CancelButton } from "components/buttons/cancel";
import { SaveButton } from "components/buttons/save";
import { BackButton } from "components/buttons/back";

export const LoginsCreate: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {
        refineCore: { queryResult, onFinish },
        register,
        control,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues: {
            Status: "Active",
            Refresh: "Monthly",
            RefreshOffset: 0,
            Name: "",
            vendors_id: null,
            downloaders_id: null,

            clients_id: null,
            URI: "",
            Username: "",
            Password: "",
            ForwardTo: "",
        },
    });

    const loginsData = queryResult?.data?.data;

    const identity = JSON.parse(localStorage.getItem("profile") as string);

    const onFinishHandler = async (data: any) => {
        const client_id = data.clients_id ? data.clients_id : undefined
        let tenant_id

        // find the tenant Id
        if (client_id) {
            const clientResult = await supabaseClient
                .from("clients")
                .select("*")
                .eq("id", client_id)

            if (clientResult.data && clientResult.data.length > 0) {
                tenant_id = clientResult.data[0].tenant_id
            } else {
                console.error(`Client not found with id: ${client_id}`)
                tenant_id = identity.tenant_id
            }
        } else {
            tenant_id = identity.tenant_id
        }

        // find the vendor_ids
        const vendorsResult = await supabaseClient
            .from("vendors")
            .select("*")
            .eq("downloaders_id", data.downloaders_id)
            .eq("tenant_id", tenant_id)

        if (vendorsResult.data && vendorsResult.data.length > 0) {
            data.vendors_id = vendorsResult.data[0].id;
            data.tenant_id = tenant_id;
        } else {
            // create the vendor record
            const downloaderResult = await supabaseClient
                .from("downloaders")
                .select("id,Name")
                .eq("id", data.downloaders_id)
                .single()
            if (!downloaderResult.data) {
                console.error(`downloaders not found with id: ${data.downloaders_id}`)
                return
            }
            // create the new vendor
            const newVendorResult = await supabaseClient
                .from("vendors")
                .insert({
                    Name: downloaderResult.data.Name,
                    downloaders_id: data.downloaders_id,
                    tenant_id: tenant_id,
                })
                .select("id")
                .single()

            if (!newVendorResult.data) {
                console.error(`new vendor ID not returned after insert: ${data.downloaders_id}`)
                return
            }
            data.vendors_id = newVendorResult.data.id;
            data.tenant_id = tenant_id;
        }

        onFinish(data)
    };

    return (
        <Create
            footerButtons={[
                <CancelButton
                    key={"cancel"}
                    sx={{
                        padding: ".25rem 2.25rem",
                        minWidth: "7.5rem",
                        textTransform: "capitalize",
                    }}
                />,
                <SaveButton
                    key={"save"}
                    sx={{
                        padding: ".25rem 2.25rem",
                        minWidth: "7.5rem",
                        textTransform: "capitalize",
                    }}
                    onClick={handleSubmit(onFinishHandler)}
                >
                    Submit
                </SaveButton>,
            ]}
            goBack={<BackButton />}
            wrapperProps={{
                className: "logins",
            }}
        >
            <Box display="flex">
                <Box component="form" flexGrow={1} autoComplete="off">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                {...register("Name", {
                                    required: "This field is required",
                                })}
                                error={!!(errors as any)?.Name}
                                helperText={(errors as any)?.Name?.message}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                type="text"
                                label={translate("Logins.fields.Name")}
                                name="Name"
                            />
                            {(identity.role === "manager" ||
                                identity.role === "admin") && (
                                    <AutoCompleteField // Not working yet.
                                        control={control}
                                        resource="clients"
                                        field="clients_id"
                                        name="Name"
                                        defaultValue={loginsData?.clients_id}
                                        label={translate("Logins.fields.Client")}
                                        error={!!(errors as any)?.Clients}
                                        helperText={
                                            (errors as any)?.Clients?.message
                                        }
                                    />
                                )}
                            <SelectField
                                name="Status"
                                label="Status"
                                control={control}
                                defaultValue={""}
                                margin="normal"
                                helperText={(errors as any)?.Status?.message}
                                error={!!(errors as any)?.Status}
                                options={statusOptions}
                                style={{ width: "100%" }}
                            />
                            <SelectField
                                // id="Refresh"
                                // {...register("Refresh", {
                                //     required: "This field is required",
                                // })}
                                name="Refresh"
                                label="Refresh"
                                control={control}
                                defaultValue={""}
                                // variant="outlined"
                                helperText={(errors as any)?.Refresh?.message}
                                margin="normal"
                                error={!!(errors as any)?.Refresh}
                                options={refreshOptions}
                                style={{ width: "100%" }}
                            />
                            <TextField
                                {...register("RefreshOffset", {
                                    required: "This field is required",
                                    valueAsNumber: true,
                                })}
                                error={!!(errors as any)?.RefreshOffset}
                                helperText={
                                    (errors as any)?.RefreshOffset?.message
                                }
                                margin="normal"
                                fullWidth
                                InputProps={{ inputProps: { min: 0, max: 31 } }}
                                InputLabelProps={{ shrink: true }}
                                type="number"
                                label={translate("Logins.fields.RefreshOffset")}
                                name="RefreshOffset"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <AutoCompleteField // Not working yet.
                                control={control}
                                resource="downloaders"
                                field="downloaders_id"
                                name="Name"
                                defaultValue={loginsData?.downloaders_id}
                                label={translate("Logins.fields.Vendor")}
                                error={!!(errors as any)?.Vendors}
                                helperText={(errors as any)?.Vendors?.message}
                            />
                            <TextField
                                {...register("URI", {
                                    required: "This field is required",
                                })}
                                error={!!(errors as any)?.URI}
                                helperText={(errors as any)?.URI?.message}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                multiline
                                label={translate("Logins.fields.URI")}
                                name="URI"
                            />
                            <TextField
                                {...register("Username", {
                                    required: "This field is required",
                                })}
                                error={!!(errors as any)?.Username}
                                helperText={(errors as any)?.Username?.message}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                type="email"
                                label={translate("Logins.fields.Username")}
                                name="Username"
                            />
                            <TextField
                                {...register("Password", {
                                    required: "This field is required",
                                })}
                                error={!!(errors as any)?.Password}
                                helperText={(errors as any)?.Password?.message}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                type="text"
                                label={translate("Logins.fields.Password")}
                                name="Password"
                            />
                            <TextField
                                {...register("ForwardTo")}
                                error={!!(errors as any)?.Name}
                                helperText={(errors as any)?.Name?.message}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                type="text"
                                label={translate("Logins.fields.ForwardTo")}
                                name="ForwardTo"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Create>
    );
};
